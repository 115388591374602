import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en, pl, sk } from "./assets/localization";
import { LOCAL_STORAGE_LNG_KEY } from "./common/data";

const FALLBACK_LNG = "en";
// const URL_LANG = window.location.pathname.split("/")[1];
// const lng =
//   (URL_LANG?.length === 2 &&
//     ["en", "pl", "sk"].includes(URL_LANG) &&
//     URL_LANG) ||
//   // localStorage.getItem(LOCAL_STORAGE_LNG_KEY) ||
//   FALLBACK_LNG;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "path",
        "localStorage",
        "sessionStorage",
        "navigator",
        "cookie",
        "querystring",
      ],
      lookupQuerystring: "lng",
      lookupCookie: "lngCookie",
      lookupLocalStorage: LOCAL_STORAGE_LNG_KEY,
      lookupSessionStorage: LOCAL_STORAGE_LNG_KEY,
      caches: ["localStorage", "cookie"],
      htmlTag: document.documentElement,
      convertDetectedLanguage: (lng) => {
        const converted = lng.split("-")?.[0];
        // return lng.replace("-", "_");
        return converted.length === 2 ? converted : undefined;
      },
    },
    resources: {
      en,
      sk,
      pl,
    },
    // lng, // if you're using a language detector, do not define the lng option
    fallbackLng: FALLBACK_LNG,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
