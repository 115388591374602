import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { sha256 } from "js-sha256";
import { Auth } from "aws-amplify";
import awsmobile from "./aws";
import i18n from "../i18n";
import EliaAuth from "./EliaAuth";
import { isDevEnv } from "./Segment";
const poolData = {
  UserPoolId: "eu-central-1_k1YYN8VCQ",
  ClientId: "523htal6nu2u0c7bs0oc18jg5s",
};
export const userPool = new CognitoUserPool(poolData);
window["userPOOL"] = userPool;
const salt = "ADwz>6G(6BZRR9mC";
Auth.configure({ ...awsmobile });
window["_Auth"] = Auth;
export const hash = (password) => sha256(password + salt);

export function GoogleSignIn() {
  Auth.federatedSignIn({ provider: "Google" }).then(() => {
    console.log("Completed", arguments);
  });
  // console.log(Cache.getItem("federatedInfo"));
  // return {
  //   onSuccess: console.log,
  //   onError: console.error,
  //   flow: "implicit",
  //   prompt: "select_account",
  // };
}
export function FacebookSignIn() {
  Auth.federatedSignIn({ provider: "Facebook" });
}
export function CognitoLogin(username, password, callback = console.log) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaLogin(username, password, callback);
  }
  let userData = {
    Username: username,
    Pool: userPool,
  };
  let authenticationData = {
    Username: username,
    Password: hash(password),
  };
  let authenticationDetails = new AuthenticationDetails(authenticationData);
  const user = new CognitoUser(userData);
  user.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      console.log("results", result);
      localStorage.setItem(
        "accessToken",
        result.getAccessToken().getJwtToken()
      );
      localStorage.setItem("idToken", result.getIdToken().jwtToken);
      localStorage.setItem("CURRENT_ID_TOKEN", result.getIdToken().jwtToken);
      localStorage.setItem("accessToken", result.getAccessToken().jwtToken);
      localStorage.setItem("refreshToken", result.getRefreshToken().token);
      sessionStorage.setItem("USER_EMAIL", username);
      localStorage.setItem("userSub", result.idToken.payload.sub);
      localStorage.setItem(
        "language",
        result.idToken.payload["custom:appLanguage"] || "en"
      );
      localStorage.setItem(
        "translations",
        result.idToken.payload["custom:translationLanguage"] || '["de"]'
      );
      sessionStorage.setItem(
        "translations",
        result.idToken.payload["custom:translationLanguage"] || '["de"]'
      );
      localStorage.setItem(
        "idTokenExpireTime",
        result.idToken.payload.exp * 1000
      );
      localStorage.setItem(
        "primaryLanguage",
        result.idToken.payload["custom:primaryLanguage"] || "de"
      );
      sessionStorage.setItem(
        "primaryLanguage",
        result.idToken.payload["custom:primaryLanguage"] || "de"
      );
      localStorage.setItem(
        "englishLevel",
        result.idToken.payload["custom:englishLanguageLevel"] || ""
      );
      sessionStorage.setItem(
        "level",
        result.idToken.payload["custom:englishLanguageLevel"] || ""
      );
      communicateWithPlugin();
      callback(null);
    },
    onFailure: callback,
  });
}
export function CognitoSignup(email, password, callback = console.log) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaRegister(email, password, callback);
  }
  let attributeList = [
    { Name: "nickname", Value: email },
    { Name: "email", Value: email },
    { Name: "custom:appLanguage", Value: "EN" },
  ];
  window["isRegister"] = true;
  userPool.signUp(email, hash(password), attributeList, null, function (err) {
    HandleErrors(email, err);
    callback(err);
    if (!err) {
      sessionStorage.setItem("USER_EMAIL", email);
    }
  });
}
export function CognitoForgetPassword(email, callback) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaForgetPassword(email, callback);
  }
  let userData = {
    Username: email,
    Pool: userPool,
  };
  const _user = new CognitoUser(userData);
  _user.forgotPassword({
    onSuccess: function (response) {
      localStorage.setItem("requestingEmail", email);
      sessionStorage.setItem("USER_EMAIL", email);
      callback(null, response);
    },
    onFailure: callback,
  });
}
export function CognitoResetPassword(verificationCode, newPassword, callback) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaResetPassword(verificationCode, newPassword, callback);
  }
  const email = localStorage.getItem("requestingEmail");
  if (!email) return callback({ code: "NO_EMAIL_FOUND" });
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const _user = new CognitoUser(userData);
  _user.confirmPassword(verificationCode, hash(newPassword), {
    onSuccess: () => {
      localStorage.removeItem("requestingEmail");
      sessionStorage.setItem("USER_EMAIL", email);
      callback(null);
    },
    onFailure: function (err) {
      callback(err);
    },
  });
}

export function CognitoUpdateLevel(level = "B2 Upper Intermediate", callback) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaUpdateLevel(level, callback);
  }
  refreshTokens(function (logged, _user) {
    if (!logged) {
      document.location.replace("/login");
      return;
    }
    const attribute = new CognitoUserAttribute({
      Name: "custom:englishLanguageLevel",
      Value: level,
    });

    _user.updateAttributes([attribute], function (err, result) {
      if (err) {
        callback(err.message || JSON.stringify(err));
        return;
      }
      sessionStorage.setItem("level", level.slice(0, 2) || "B2");
      communicateWithPlugin("FORCE_UPDATE");
      callback(null, result);
    });
  });
}
export function CognitoUpdateLanguages(
  languages = [],
  primary = "de",
  appLanguage = "EN",
  callback = () => {}
) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaUpdateLanguages(languages, primary, appLanguage, callback);
  }
  refreshTokens(function (logged, _user) {
    if (!logged) {
      document.location.replace("/login");
      return;
    }
    const attribute = new CognitoUserAttribute({
      Name: "custom:translationLanguage",
      Value: JSON.stringify(languages),
    });
    const primaryAttribute = new CognitoUserAttribute({
      Name: "custom:primaryLanguage",
      Value: primary,
    });

    _user.updateAttributes(
      [primaryAttribute, attribute],
      function (err, result) {
        if (err) {
          callback(err.message || JSON.stringify(err));
          return;
        }
        sessionStorage.setItem(
          "translations",
          JSON.stringify(languages) || '["de"]'
        );
        sessionStorage.setItem("primaryLanguage", primary || "de");
        communicateWithPlugin("FORCE_UPDATE");
        callback(null, result);
      }
    );
  });
}
async function refreshTokens(callback) {
  const _user = userPool.getCurrentUser();
  if (!_user) return window.location.replace("/login");
  if (_user && _user.signInUserSession && _user.signInUserSession.isValid()) {
    _user.Session = _user.signInUserSession;
  }
  const expirationTime = localStorage.getItem("idTokenExpireTime");
  if (!expirationTime)
    localStorage.setItem("idTokenExpireTime", new Date().getTime());
  if (
    Date.now() + 5 * 60 * 1000 < +expirationTime &&
    _user &&
    _user.Session &&
    _user.Session.isValid()
  ) {
    communicateWithPlugin();
    return callback(true, _user);
  }
  _user.getSession(function (err, session) {
    _user.Session = session;
    if (err || !session.isValid()) return callback(false);
    if (Date.now() + 5 * 60 * 1000 > session.idToken.payload.exp * 1000) {
      const _refreshToken = session.getRefreshToken();
      _user.refreshSession(_refreshToken, function (err, newSession) {
        if (err) return callback(false);
        localStorage.setItem("accessToken", newSession.accessToken.jwtToken);
        localStorage.setItem("idToken", newSession.idToken.jwtToken);
        localStorage.setItem("CURRENT_ID_TOKEN", newSession.idToken.jwtToken);
        localStorage.setItem(
          "idTokenExpireTime",
          newSession.idToken.payload.exp * 1000
        );
        localStorage.setItem("userSub", newSession.idToken.payload.sub);
        localStorage.setItem(
          "language",
          newSession.idToken.payload["custom:appLanguage"] || "english"
        );
        callback(true, _user);
      });
    } else {
      localStorage.setItem("accessToken", session.accessToken.jwtToken);
      localStorage.setItem("idToken", session.idToken.jwtToken);
      localStorage.setItem(
        "idTokenExpireTime",
        session.idToken.payload.exp * 1000
      );
      localStorage.setItem("userSub", session.idToken.payload.sub);
      localStorage.setItem(
        "language",
        session.idToken.payload["custom:appLanguage"] || "english"
      );
      return callback(true, _user);
    }
  });
}
export function communicateWithPlugin(
  QUERY = "LOG_USER_IN",
  callback = () => {}
) {
  if (sessionStorage.getItem("EliaAuth")) {
    return EliaCommunicateWithPlugin(QUERY, callback);
  }
  const pluginframe = document.querySelector("iframe#ELIA_IFRAME");
  if (pluginframe) {
    pluginframe.contentWindow.postMessage(
      {
        query: QUERY || "LOG_USER_IN",
        payload: JSON.stringify({
          tokens: {
            accessToken: localStorage.getItem("accessToken"),
            idToken: localStorage.getItem("idToken"),
            refreshToken: localStorage.getItem("refreshToken"),
          },
          storage: localStorage,
          session: userPool.getCurrentUser()?.signInUserSession,
          isRegister: window["isRegister"],
        }),
      },
      "*"
    );
    return callback(true);
  } else {
    console.log("Iframe not found");
  }
  callback(false);
}
function HandleErrors(email, error) {
  if (!error) return;
  if (new URLSearchParams(window.location.search).get("enable-test") === "1")
    return console.log("TEST ENABLED");
  if (!email) return;
  fetch("/api/error.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ error: JSON.stringify(error || ""), email }),
  })
    .then((res) => res.text())
    .catch(console.error);
}
export function UserSignupSuccess(
  email,
  subscribe,
  userID,
  isNew = true,
  firstName,
  lastName
) {
  if (isNew && !isDevEnv())
    window.dataLayer?.push(
      { event: "REGISTER_COMPLETED", label: "REGISTER_COMPLETED" },
      function () {
        console.log("REGISTER_COMPLETED");
      }
    );
  if (!email) return;
  if (!isNew) return;
  const anonymousID = localStorage.getItem("anonymousID");
  fetch("/api/success.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      subscribe,
      id: anonymousID,
      user_id: userID,
      firstName,
      lastName,
    }),
  })
    .then((res) => res.text())
    .catch(console.error);
}
export function BackendTrack(event, email) {
  const anonymousId = localStorage.getItem("anonymousID");
  const version = sessionStorage.getItem("version");
  const { isDev } = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  const ab_testing = sessionStorage.getItem("ab_testing");
  fetch("/api/event.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      event,
      anonymousId,
      version,
      isDev,
      ab_testing,
    }),
  })
    .then((res) => res.text())
    .catch(console.error);
}
export function urlQueries() {
  return Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
}
export function TrackSocialCompleted(type = "REGISTER", params) {
  return new Promise((resolve, reject) => {
    console.log("TRACK SOCIAL");
    Auth.currentAuthenticatedUser().then((user) => {
      if (user && user.attributes) {
        const { email } = user.attributes;
        UserSignupSuccess(email, true);
        const anonymousId = localStorage.getItem("anonymousID");
        const version = sessionStorage.getItem("version");
        const { isDev } = Object.fromEntries(
          new URLSearchParams(params || window.location.search).entries()
        );
        const ab_testing = sessionStorage.getItem("ab_testing");
        fetch("/api/event.php", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            event: type === "REGISTER" ? "SIGNUP_SUCCESSFUL" : "LOGIN_SUCCESS",
            anonymousId,
            version,
            isDev,
            ab_testing,
          }),
        })
          .then((res) => res.text())
          .then(resolve)
          .catch((err) => {
            resolve(false);
            console.error(err);
          });
      }
    });
  });
}
export function EliaLogin(username, password, callback = console.log) {
  EliaAuth.login(username, password, function (err, payload) {
    if (err) {
      if (!err.statusText && !err.response) {
        const status = err.status;
        const message =
          status === 401 ? `Invalid email or password` : `Server Error`;
        return callback({ message });
      }
      return callback(err.statusText || err.response);
    }
    const userTokens = payload.response;
    localStorage.setItem("eliaAccessToken", userTokens.access_token);
    localStorage.setItem("eliaRefreshToken", userTokens.refresh_token);
    localStorage.setItem("CURRENT_ID_TOKEN", userTokens.access_token);
    localStorage.setItem("USER_EMAIL", username);
    window["isRegister"] = userTokens.is_new;
    EliaCommunicateWithPlugin();
    callback(null, payload);
  });
}
export function EliaRegister(username, password, callback = console.log) {
  EliaAuth.register(username, password, function (err, payload) {
    if (err) {
      if (!err.statusText && !err.response) {
        const status = err.status;
        const message =
          status === 401 ? `Invalid email or password` : `Server Error`;
        callback({ message });
        HandleErrors(username, { message });
      } else {
        callback(err.response || err.statusText);
        HandleErrors(username, err.response || err.statusText);
      }
    }
    if (!err) {
      const userTokens = payload.response;
      localStorage.setItem("eliaAccessToken", userTokens.access_token);
      localStorage.setItem("eliaRefreshToken", userTokens.refresh_token);
      localStorage.setItem("CURRENT_ID_TOKEN", userTokens.access_token);
      localStorage.setItem("USER_EMAIL", username);
      window["isRegister"] = userTokens.is_new;
      EliaCommunicateWithPlugin();
      sessionStorage.setItem("USER_EMAIL", username);
      callback(null, payload);
    }
  });
}
export function EliaForgetPassword(email, callback) {
  EliaAuth.requestPassword(email, function (err) {
    if (err) {
      if (!err.statusText && !err.response) {
        const status = err.status;
        const message =
          status === 400 ? `incorrect request body` : `Server Error`;
        return callback({ message });
      }
      return callback(err.statusText || err.response);
    }
    localStorage.setItem("requestingEmail", email);
    sessionStorage.setItem("USER_EMAIL", email);
    callback(null);
  });
}
export function EliaResetPassword(verificationToken, newPassword, callback) {
  EliaAuth.resetPassword(newPassword, verificationToken, function (err) {
    if (err) {
      if (err.status === 401) {
        return document.location.replace("/password-reset/invalid");
      }
      if (!err.statusText && !err.response) {
        const status = err.status;
        const message =
          status === 400 ? `token or password fields missing` : `Server Error`;
        return callback({ message });
      }
      return callback(err.statusText || err.response);
    }
    const email = localStorage.getItem("requestingEmail");
    localStorage.removeItem("requestingEmail");
    sessionStorage.setItem("USER_EMAIL", email);
    callback(null);
  });
}
export function EliaUpdateLevel(level = "B2 Upper Intermediate", callback) {
  EliaAuth.updateUserAttribute({ user_level: level }, callback);
}
export function EliaUpdateLanguages(
  languages = [],
  primary = "de",
  appLanguage = "EN",
  callback = console.log
) {
  EliaAuth.updateUserAttribute(
    {
      primary_language: primary,
      translation_language: languages,
      app_language: appLanguage,
    },
    callback
  );
}
export function EliaCommunicateWithPlugin(
  QUERY = "LOG_USER_IN",
  callback = () => {}
) {
  console.log("Communicate with plugin", i18n.language);

  const pluginFrame = document.querySelector("iframe#ELIA_IFRAME");
  if (pluginFrame) {
    pluginFrame.contentWindow.postMessage(
      {
        query: QUERY || "LOG_USER_IN",
        payload: JSON.stringify({
          appLanguage: i18n.language,
          tokens: {
            accessToken: localStorage.getItem("eliaAccessToken"),
            refreshToken: localStorage.getItem("eliaRefreshToken"),
          },
          isRegister: window["isRegister"],
        }),
      },
      "*"
    );
    return callback(true);
  } else {
    console.log("Iframe not found");
  }
  callback(false);
}
export function EliaGetUser(callback = console.log) {
  EliaAuth.getUser(callback);
}
