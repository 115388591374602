import * as amplitude from "@amplitude/analytics-browser";
import { amplitudeDevKey, amplitudeLiveKey } from "./data";
export function isDevEnv() {
  const { isDev } = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  const isDevMode =
    process.env.NODE_ENV === "development" ||
    isDev ||
    window.location.hostname.indexOf("dev.") !== -1;
  return isDevMode;
}
function initAmp() {
  const isDevMode = isDevEnv();
  amplitude.init(isDevMode ? amplitudeDevKey : amplitudeLiveKey, undefined, {
    defaultTracking: {
      sessions: true,
      pageViews: false,
      formInteractions: false,
      attribution: false,
    },
    identityStorage: `localStorage`,
    serverUrl: "/services/httpapi?v2=enabled",
    // sessionId: Date.now(),
  });
}
initAmp();

function getCommonUserProperties() {
  if (sessionStorage.getItem("newSpecsFlag")) {
    const userData = JSON.parse(
      sessionStorage.getItem("EliaUserAttributes") || "{}"
    );
    return userData;
  } else {
    return {
      userReferrer: sessionStorage.getItem("userReferrer"),
      landingPage: sessionStorage.getItem("landingPage"),
      turnedOnAllWebsites: !!sessionStorage.getItem("turn_on_all_websites"),
      featureLookup: !!sessionStorage.getItem("word_lookup"),
      featureRecommendation: sessionStorage.getItem("word_recommendation"),
      featurePractice: sessionStorage.getItem("word_practice"),
      primaryLanguage: sessionStorage.getItem("primaryLanguage"),
      englishLevel: sessionStorage.getItem("level")?.slice(0, 2),
    };
  }
}
function getCommonEventProperties() {
  const pluginVersion = sessionStorage.getItem("version");
  const timestamp = new Date().toISOString();
  return {
    source: "getelia",
    eventCategory: "login",
    eventType: "userRequestToWebsite",
    isLearningEvent: false,
    timestamp,
    pluginVersion,
  };
}
function parseToken(token) {
  if (!token) return {};
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (exception) {
    return {};
  }
}
export function setUserID(userID, token) {
  const user = userID || parseToken(token)?.user_id;
  localStorage.setItem("Elia_USER_ID", user);
  amplitude.setUserId(user);
  if (token) {
    return parseToken(token)?.user_id;
  }
  return userID;
}
export default function useAnalytics() {
  function getLibrary() {
    return amplitude;
  }
  function getUserEmail() {
    return new Promise(async (r) => {
      try {
        if (window["ELIA_CURRENT__EMAIL"])
          return r(window["ELIA_CURRENT__EMAIL"]);
        window["ELIA_CURRENT__EMAIL"] = localStorage.getItem("USER_EMAIL");
        r(localStorage.getItem("USER_EMAIL"));
        return;
      } catch (ex) {
        r(undefined);
      }
    });
  }
  async function track(
    eventName,
    eventCategory,
    eventType = "userRequestToWebsite",
    extra = {},
    callback = () => {},
    extraUserAttributes = {}
  ) {
    const isDev = isDevEnv();
    const userEmail = await getUserEmail();
    const userData = {
      ...getCommonUserProperties(),
      ...extraUserAttributes,
    };
    if (!userData["email"]) {
      userData["email"] = userEmail;
    }
    const data = {
      ...getCommonEventProperties(),
      event: eventName,
      source: "getelia",
      eventCategory: eventCategory,
      eventType: eventType,
      isLearningEvent: false,
      ...extra,
    };
    if (isDev) {
      data["original_event_name"] = eventName;
      data["event"] = "DEV " + eventName;
    }
    if (!userData["userReferrer"] || userData["userReferrer"] === "none") {
      userData["userReferrer"] = document
        .querySelector(`meta[name="userReferrer"]`)
        ?.getAttribute("content");
    }
    if (!userData["landingPage"] || userData["landingPage"] === "none") {
      userData["landingPage"] = document
        .querySelector(`meta[name="userVariant"]`)
        ?.getAttribute("content");
    }
    return getLibrary().track(
      (isDev ? "DEV " : "") + eventName,
      data,
      {
        user_properties: { $set: userData },
      },
      callback
    );
  }
  async function identify(extra = {}) {
    const userEmail = await getUserEmail();
    const userData = {
      ...getCommonUserProperties(),
      ...extra,
    };
    if (!userData["email"]) {
      userData["email"] = userEmail;
    }
    if (!userData["userReferrer"] || userData["userReferrer"] === "none") {
      userData["userReferrer"] = document
        .querySelector(`meta[name="userReferrer"]`)
        ?.getAttribute("content");
    }
    if (!userData["landingPage"] || userData["landingPage"] === "none") {
      userData["landingPage"] = document
        .querySelector(`meta[name="userVariant"]`)
        ?.getAttribute("content");
    }
    const identity = new amplitude.Identify();
    Object.entries(userData).forEach((property) => {
      identity.set(...property);
    });
    identity.unset("appNone", "");
    getLibrary().identify(identity);
  }
  function backendTrack(eventName, pageName) {
    let anonymousID = amplitude.getDeviceId();
    const isDev = isDevEnv();
    const { pathname } = window.location;
    const category =
      pathname.indexOf("/login") !== -1
        ? "login"
        : pathname.indexOf("/pricing") !== -1 || pathname.indexOf("/thank-you")
        ? "subscription"
        : "register";
    const version = sessionStorage.getItem("version");
    const session_id = amplitude.getSessionId();
    const time = new Date().getTime();
    const url = `/services/t?env=${isDev}&v=${version}&e=${eventName}&aid=${anonymousID}&p=${pageName}&c=${category}&sid=${session_id}&t=${time}`;
    fetch(url)
      .then((res) => res.json())
      .catch((err) => err);
  }
  function postBackendTracking(
    eventName,
    eventCategory,
    eventType,
    extra = {},
    userExtra = {}
  ) {
    let anonymousID = amplitude.getDeviceId();
    const user_id = amplitude.getUserId();
    const isDev = isDevEnv();
    const eventData = {
      ...getCommonEventProperties(),
      event: eventName,
      source: "getelia",
      eventCategory: eventCategory,
      eventType: eventType,
      isLearningEvent: false,
      ...extra,
    };

    const userData = {
      ...getCommonUserProperties(),
      ...userExtra,
    };
    if (isDev) {
      eventData["original_event_name"] = eventName;
      eventData["event"] = "DEV " + eventName;
    }
    if (!userData["userReferrer"] || userData["userReferrer"] === "none") {
      userData["userReferrer"] = document
        .querySelector(`meta[name="userReferrer"]`)
        ?.getAttribute("content");
    }
    if (!userData["landingPage"] || userData["landingPage"] === "none") {
      userData["landingPage"] = document
        .querySelector(`meta[name="userVariant"]`)
        ?.getAttribute("content");
    }
    const session_id = amplitude.getSessionId();
    const time = new Date().getTime();
    fetch("/services/t", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event_properties: eventData,
        user_properties: userData,
        device_id: anonymousID,
        time,
        session_id,
        user_id,
        isDev,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.error);
  }
  return [track, backendTrack, identify, postBackendTracking];
}
