import { sha256 } from "js-sha256";

const salt = "ADwz>6G(6BZRR9mC";

class EliaAuth {
  static endpoint = "/api/auth.php?action=";
  /**
   * Login user
   * @param {String} username user login
   * @param {String} password user password ** Will be hashed
   * @param {Function} callback (err,userTokens)
   */
  static async login(
    username,
    password,
    callback = console.log,
    skipHash = true
  ) {
    this.performAction(
      "login",
      {
        body: JSON.stringify({
          login: username,
          password: skipHash ? password : this.#hash(password),
        }),
      },
      callback
    );
  }
  /**
   * Register new user
   * @param {String} username user email
   * @param {String} password user password
   * @param {Function} callback (err,userTokens)
   */
  static async register(
    username,
    password,
    callback = console.log,
    skipHash = true
  ) {
    this.performAction(
      "register",
      {
        body: JSON.stringify({
          login: username,
          password: skipHash ? password : this.#hash(password),
        }),
      },
      callback
    );
  }
  /**
   * Hash a string to sha256
   * @param {String} password to hash
   * @returns {String}
   */
  static #hash(password) {
    return sha256(password + salt);
  }
  /**
   * Forward call to backend handler
   * @param {String} type action type (login,register ...)
   * @param {Object} payload Fetch options
   * @param {Function} callback (err,results) called after action completed
   */
  static async performAction(type, payload, callback = console.log) {
    const { isDev } = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    fetch(`${this.endpoint}${type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-IS-DEV": isDev,
      },
      ...payload,
    })
      .then(async (response) => {
        const headers = Object.fromEntries(response.headers.entries());
        const data = {
          ok: response.ok,
          redirected: response.redirected,
          status: response.status,
          statusText: response.statusText,
          response:
            headers["content-type"]?.indexOf("json") !== -1
              ? await response.json()
              : await response.text(),
          headers,
        };
        if (response.ok) {
          return data;
        }
        throw data;
      })
      .then((response) => {
        callback(null, response);
      })
      .catch(callback);
  }
  /**
   * Login/Register user through Google oauth
   * @param {String} token Google oauth token
   * @param {Function} callback (err,userTokens) called after action
   */
  static googleSignIn(token, callback = console.log) {
    this.performAction(
      "login",
      {
        body: JSON.stringify({
          google_code: token,
        }),
      },
      callback
    );
  }
  /**
   * Login/Register user through Facebook login
   * @param {String} token Facebook token
   * @param {Function} callback (err,userTokens) called after action
   */
  static facebookSignIn(token, callback = console.log) {
    this.performAction(
      "login",
      {
        body: JSON.stringify({
          facebook_token: token,
        }),
      },
      callback
    );
  }
  /**
   * Update user attributes
   * @param {Object} attributes to update keys {user_level|email|primary_language|app_language|translation_language}
   * @param {Function} callback (err) called after action
   */
  static updateUserAttribute(attributes = {}, callback = console.log) {
    const userAccessToken = localStorage.getItem("eliaAccessToken");
    this.performAction(
      "attributes",
      {
        body: JSON.stringify({
          access_token: userAccessToken,
          attributes,
        }),
        method: "PUT",
      },
      callback
    );
  }
  /**
   * Request password change
   * @param {String} email email to reset password
   * @param {Function} callback (err) called after action
   */
  static requestPassword(email, callback = console.log) {
    this.performAction(
      "password/request",
      {
        body: JSON.stringify({
          email,
        }),
      },
      callback
    );
  }
  /**
   * Update user password through reset
   * @param {String} newPassword user's password ** Will be hashed
   * @param {String} token Token received by email
   * @param {Function} callback (err) called after action is completed
   */
  static resetPassword(newPassword, token, callback = console.log) {
    this.performAction(
      "password/reset",
      {
        body: JSON.stringify({ password: newPassword, token }),
      },
      callback
    );
  }
  /**
   * Get User Information
   * @param {Function} callback (err,userData)
   */
  static getUser(callback = console.log) {
    const access_token = localStorage.getItem("eliaAccessToken");
    const { isDev } = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    this.performAction(
      "user",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-IS-DEV": isDev,
          "X-ELIA-TOKEN": `Bearer ${access_token}`,
        },
      },
      callback
    );
  }
}
window["ELIA_AUTH"] = EliaAuth;
export default EliaAuth;
