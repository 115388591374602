/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:a14c2145-24af-4b0f-83be-05b76cebdd01",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_k1YYN8VCQ",
  aws_user_pools_web_client_id: "523htal6nu2u0c7bs0oc18jg5s",
  oauth: {
    domain: "elia.auth.eu-central-1.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://getelia.com/socialcall/",
    redirectSignOut: "https://getelia.com/socialout/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["FACEBOOK", "GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
