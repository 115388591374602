export const en = {
  translation: {
    loginTitle: "Log in to Elia",
    login: "Login",
    register: "Register",
    here: "here",
    slogan: `“I’ve learnt more words with Elia in one month than ever!”`,
    author: "Kirsten Storz, student, 24 years",
    google: "Continue with Google",
    facebook: "Continue with Facebook",
    termsText:
      "By tapping continue, you agree to our <2>Terms of Service</2> and <6>Privacy Policy</6>",
    or: "or",
    loginWith: "{{type}} with Email",
    installedMessage: "Elia is installed! 🎉 ",
    toUse: "To use Elia, you need to log in.",
    toUseRegister: `To use Elia, set it up in 6 quick steps.`,
    forgotPassword: "Forgot Password?",
    enterNewEmail: "Enter your email address to set a new password.",
    send: "Send",
    dontHaveAccountYet: "Don’t have an account yet?",
    registerHere: "Register here",
    email: "Email",
    startForFree: `Start for free`,
    password: "Password",
    agreeLogin:
      "By tapping log in, you agree to our <2>Terms of Service</2> and <6>Privacy Policy</6>.",

    startLearning: "Go to any English website",
    goToPopular:
      "To start using Elia, go to any English website <br /> or select one from below:",
    click: "Click",
    openElia: "to open Elia  anytime",

    // for steps
    "Log in": "Log in",
    "Start Learning": "Start Learning",

    haveAccount: "Already have an account?",
    loginHere: "Login here",
    confirmPassword: "Confirm Password",
    agreeRegister:
      "By tapping register, you agree to our <2>Terms of Service</2> and <6>Privacy Policy</6>.",
    Registration: "Registration",
    "Native Language": "Native Language",
    "English Level": "Select Level",
    "Select Age": "Select Age",
    "Select Use": "Select Use",

    validEmail: "*Enter a valid email.",
    emailRequired: "*Email required!",
    passwordRequired: "*Password required!",
    confirmPasswordRequired: "*Confirm password required!",
    mustContain: "*The password must contain at least 5 characters.",
    dontMatch: "*Passwords do not match.",

    age: "Age",
    howOld: "How old are you?",
    "17 or less": "17 or less",
    "18 - 24": "18 - 24",
    "25 - 34": "25 - 34",
    "35 - 44": "35 - 44",
    "45 or more": "45 or more",

    engLvl: "English Level",
    completedLvl: "My completed English level is ...",
    elementary: "A2 Starter",
    intermediate: "B1 Intermediate",
    upperIntermediate: "B2 Upper-intermediate",
    advanced: "C1 Advanced",
    proficient: "C2 Professional",
    notSure: "Not sure? Just take a guess. You can change this later.",

    open: "Open Elia",
    installed:
      "Elia is an app installed in your browser. <br /> You can open Elia like this ↓",
    translations: "Native Language",
    wantTranslations: "My native (most proficient) language is:",
    mostPopular: "Most Common",
    cs: "Czech",
    fr: "French",
    de: "German",
    fa: "Persian",
    pt: "Portuguese",
    pl: "Polish",
    sk: "Slovak",
    es: "Spanish",
    others: "Others",

    af: "Afrikaans",
    ar: "Arabic",
    bn: "Bangla",
    bs: "Bosnian",
    bg: "Bulgarian",
    ca: "Catalan",
    zh: "Chinese",
    hr: "Croatian",
    da: "Danish",
    nl: "Dutch",
    et: "Estonian",
    fi: "Finnish",
    el: "Greek",
    ht: "Haitian Creole",
    he: "Hebrew",
    hi: "Hindi",
    hm: "Hmong Daw",
    hu: "Hungarian",
    is: "Icelandic",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    kl: "Klingon",
    ko: "Korean",
    lv: "Latvian",
    lt: "Lithuanian",
    ms: "Malay",
    mt: "Maltese",
    no: "Norwegian",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sw: "Swahili",
    sv: "Swedish",
    ta: "Tamil",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    vi: "Vietnamese",
    cy: "Welsh",

    use: "Use",
    planToUse: "I plan to use Elia:",

    highSchool: "high school studies",
    university: "university studies",
    workFreelance: "work as a freelancer",
    workCorporate: "work in a corporate setting",
    workSmallBusiness: "work in a small business / startup",
    freeTime: "in my free time",

    next: "NEXT",

    enjoy: "Go and enjoy",
    freeVersion: "the free version of Elia!",
    thanks: "Thanks from the Elia team!",
    successSub: "You have successfully subscribed.",
    takeMe: "Take me to an English website",
    nowGo: "Now, go and level up your English.",
    choose: "Choose your plan",
    currentPlan: "Your current plan",
    perMonth: "per month",
    billedYearly: "Billed Yearly",
    billedMontly: "Billed Monthly",
    saved: "save 60%",
    twoCoffees: "for 2 coffees a month",
    packOfCoffee: "for a pack of good coffee",
    coffeeMonth: "for 1 coffee a month",
    billedYearlyAs: "billed yearly as {{price}}€",
    choosePlan: "Choose your plan",
    allPlans: "All plans have",

    "7 Days Free Trial": "7 Days Free Trial",
    "Unlimited Word Lookups": "Unlimited Word Lookups",
    "Unlimited Word Recommendations": "Unlimited Word Recommendations",
    "Unlimited Word Practice": "Unlimited Word Practice",
    "Translations into 50+ languages": "Translations into 50+ languages",
    "Unlimited Words in Wordlist": "Unlimited Words in Wordlist",
    "PDF Integration": "PDF Integration",
    "Daily Goal Progress": "Daily Goal Progress",
    "100% Data Security": "100% Data Security",
    mostPopularCap: "MOST POPULAR",
    freeTrial: "Start 7 days free trial",
    keepLearning: "I want to keep learning.",
    unlimitedLoookups: "Unlimited Word Lookups",
    wordRecommend: "Word Recommendations",
    wordPractice: "Word Practice",
    wordsWordlist: "Words in Wordlist",
    dailyGoal: "Daily Goal Progress",
    pdfIntegration: "PDF Integration",
    unlimited: "Unlimited",
    fasterLearning9x: "I want 9x faster learning",
    fasterLearning3x: "I want 3x faster learning",

    resent: "We have sent a code to reset the password to your email.",
    enterCode: "Enter the code here.",
    code: "Code",
    newPassword: "Enter your new password.",
    confirm: "Confirm",
    didntReceive: "Didn't receive your reset email?",
    didntReceiveText: `
    👉 Check your spam folder.
    <br />
    👉 Check whether you entered correct email address.
    <br />
    👉 Try again in an hour.
    <br />
    👉 Contact us: support@getelia.com.
    `,
    resend: "Resend",
    linkExpired: "Link Has Expired",
    cantReset: `Can't reset your password? Maybe it took you more than one hour to
    click on the link in the email we sent you.`,
    clickRequest: "👉 Click Request new email",
    requestNewEmail: "Request new email",
    codeRequired: "*Code required!",
    Monthly: `Monthly`,
    Yearly: `Yearly`,
    Lifetime: `Lifetime`,
    save: `save`,
    "per month": "per month",
    "per year": "per year",
    "one time": "one time",
    cancel: `Cancel anytime.`,
    "Users usually master": "Users usually master",
    "words & phrases": "words & phrases",
    "in this plan.": "in this plan.",
    "The sky is the limit": "The sky is the limit",
    "This is an advanced phrase for": "This is an advanced phrase for",
    unlimited: "unlimited",
    "You with Elia": "You with Elia",
  },
};
