// import i18n from "i18next";
import i18n from "../../i18n";
import React, { useState } from "react";
import { LOCAL_STORAGE_LNG_KEY } from "../../common/data";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { EliaCommunicateWithPlugin } from "../../common/Cognito";

const availableLanguages = {
  en: {
    icon: (
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8296_18990)">
          <path
            d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5Z"
            fill="#F0F0F0"
          />
          <path
            d="M1.85902 4.02051C1.15206 4.9403 0.618988 6.00044 0.308594 7.15212H4.99063L1.85902 4.02051Z"
            fill="#0052B4"
          />
          <path
            d="M17.6898 7.15209C17.3794 6.00044 16.8463 4.9403 16.1394 4.02051L13.0078 7.15209H17.6898Z"
            fill="#0052B4"
          />
          <path
            d="M0.308594 11.8477C0.619023 12.9993 1.1521 14.0594 1.85902 14.9792L4.99053 11.8477H0.308594Z"
            fill="#0052B4"
          />
          <path
            d="M14.4792 2.36052C13.5594 1.65356 12.4993 1.12049 11.3477 0.810059V5.49206L14.4792 2.36052Z"
            fill="#0052B4"
          />
          <path
            d="M3.51953 16.6393C4.43932 17.3463 5.49946 17.8794 6.65111 18.1898V13.5078L3.51953 16.6393Z"
            fill="#0052B4"
          />
          <path
            d="M6.65107 0.810059C5.49943 1.12049 4.43929 1.65356 3.51953 2.36048L6.65107 5.49203V0.810059Z"
            fill="#0052B4"
          />
          <path
            d="M11.3477 18.1898C12.4993 17.8794 13.5594 17.3463 14.4792 16.6394L11.3477 13.5078V18.1898Z"
            fill="#0052B4"
          />
          <path
            d="M13.0078 11.8477L16.1394 14.9792C16.8463 14.0595 17.3794 12.9993 17.6898 11.8477H13.0078Z"
            fill="#0052B4"
          />
          <path
            d="M17.9238 8.3261H10.174H10.1739V0.576184C9.78964 0.526156 9.39786 0.5 9 0.5C8.60207 0.5 8.21035 0.526156 7.8261 0.576184V8.32603V8.32606H0.0761836C0.0261563 8.71036 0 9.10214 0 9.5C0 9.89793 0.0261563 10.2896 0.0761836 10.6739H7.82603H7.82606V18.4238C8.21036 18.4738 8.60207 18.5 9 18.5C9.39786 18.5 9.78964 18.4739 10.1739 18.4238V10.674V10.6739H17.9238C17.9738 10.2896 18 9.89793 18 9.5C18 9.10214 17.9738 8.71035 17.9238 8.3261Z"
            fill="#D80027"
          />
          <path
            d="M11.3477 11.8477L15.3638 15.8638C15.5485 15.6792 15.7247 15.4861 15.8928 15.286L12.4544 11.8477H11.3477V11.8477Z"
            fill="#D80027"
          />
          <path
            d="M6.65286 11.8477H6.65279L2.63672 15.8637C2.82136 16.0484 3.0144 16.2246 3.21451 16.3928L6.65286 12.9543V11.8477Z"
            fill="#D80027"
          />
          <path
            d="M6.65061 7.15244V7.15237L2.6345 3.13623C2.44979 3.32087 2.27359 3.51391 2.10547 3.71402L5.54386 7.15241H6.65061V7.15244Z"
            fill="#D80027"
          />
          <path
            d="M11.3477 7.15211L15.3638 3.13593C15.1792 2.95122 14.9861 2.77502 14.786 2.60693L11.3477 6.04532V7.15211Z"
            fill="#D80027"
          />
        </g>
        <defs>
          <clipPath id="clip0_8296_18990">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  sk: {
    icon: (
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8280_24383)">
          <path
            d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5Z"
            fill="#F0F0F0"
          />
          <path
            d="M9 18.5C13.9705 18.5 18 14.4706 18 9.50005C18 8.39916 17.802 7.34458 17.4402 6.36963H0.559793C0.198035 7.34458 0 8.39916 0 9.50005C0 14.4706 4.02947 18.5 9 18.5Z"
            fill="#0052B4"
          />
          <path
            d="M8.9988 18.5C12.8685 18.5 16.1674 16.0576 17.439 12.6304H0.558594C1.83023 16.0576 5.12912 18.5 8.9988 18.5Z"
            fill="#D80027"
          />
          <path
            d="M2.32812 5.58691V10.0766C2.32812 12.6305 5.66449 13.413 5.66449 13.413C5.66449 13.413 9.00082 12.6305 9.00082 10.0766V5.58691H2.32812Z"
            fill="#F0F0F0"
          />
          <path
            d="M3.10938 5.58691V10.0766C3.10938 10.3764 3.17596 10.6595 3.30787 10.925H8.01835C8.15025 10.6596 8.21684 10.3764 8.21684 10.0766V5.58691H3.10938Z"
            fill="#D80027"
          />
          <path
            d="M7.22807 8.71743H6.05421V7.93482H6.83682V7.15221H6.05421V6.36963H5.27159V7.15221H4.48902V7.93482H5.27159V8.71743H4.09766V9.50005H5.27159V10.2827H6.05421V9.50005H7.22807V8.71743Z"
            fill="#F0F0F0"
          />
          <path
            d="M4.37604 12.0173C4.88036 12.3343 5.39849 12.5183 5.66375 12.6001C5.929 12.5183 6.44713 12.3343 6.95145 12.0173C7.46002 11.6977 7.81654 11.3321 8.01897 10.9248C7.79569 10.7669 7.52327 10.6737 7.22897 10.6737C7.12182 10.6737 7.01772 10.6864 6.91763 10.7097C6.70553 10.2278 6.22407 9.89111 5.66378 9.89111C5.1035 9.89111 4.622 10.2278 4.40993 10.7097C4.30984 10.6864 4.20571 10.6737 4.09859 10.6737C3.8043 10.6737 3.53187 10.7669 3.30859 10.9248C3.51092 11.3321 3.86744 11.6977 4.37604 12.0173Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_8280_24383">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  pl: {
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
          fill="#F0F0F0"
        />
        <path
          d="M18 9C18 13.9705 13.9705 18 9 18C4.02947 18 0 13.9705 0 9"
          fill="#D80027"
        />
      </svg>
    ),
  },
};

export const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { lng: url_lng } = useParams();
  const { pathname } = useLocation();
  const lng = i18n.language;
  const navigate = useNavigate();
  const toggleSelector = () => setIsOpen((prevState) => !prevState);
  const selectLanguage = (key) => {
    i18n.changeLanguage(key);
    localStorage.setItem(LOCAL_STORAGE_LNG_KEY, key);
    toggleSelector();
    const nextPath = pathname.replace(url_lng, key);
    navigate(`${nextPath}`);
    EliaCommunicateWithPlugin("LNG_UPDATED");
  };

  return (
    <div className="LngSelector-container">
      <div className="LngSelector-selected" onClick={toggleSelector}>
        {availableLanguages[lng]?.icon || availableLanguages.en.icon}
        <p className="LngSelector-text">{lng}</p>
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 6L6 1L1 6"
            stroke="black"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="LngSelector-list">
          {Object.entries(availableLanguages).map(([key, value]) => {
            return (
              <div
                key={key}
                className="LngSelector-option"
                onClick={() => selectLanguage(key)}
              >
                {value.icon}
                <p>{key}</p>
              </div>
            );
          })}
        </div>
      )}
      <style>
        {css}
        {i18n.language !== "en" &&
          `.MainTitle{font-family: var(--inter)!important;}`}
      </style>
    </div>
  );
};

const css = `
  .LngSelector-container {
    position: absolute;
    top: 50px;
    right: 60px;
    z-index:10;

  }

  .LngSelector-selected {
    cursor: pointer;
    color: #000;
    
    padding: 13px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 4px;
    transition: all 0.5s;
    background: #fff;
  }

  .LngSelector-text, .LngSelector-option p { 
    margin-left: 5px;
    margin-right: 10px;
    text-transform: uppercase;
    color: #000;
  }

  .LngSelector-list {
    margin-top: 10px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 4px;
    overflow: hidden;
  }

  .LngSelector-option {
    padding: 13px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  
    background: #fff;
    transition: all 0.5s;
    justify-content: flex-start !important;
    border-top: 1px;
    
  }
  

  .LngSelector-option:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;
