export const sk = {
  translation: {
    loginTitle: "Prihlásiť sa do Elie",
    login: "Prihlásiť sa",
    register: "Registrácia",
    here: "tu",
    slogan: `“Za mesiac som sa s Eliou naučila viac slov ako kedykoľvek predtým!”`,
    author: "Kirsten Storz, študentka, 24 rokov",
    google: "Pokračovať so službou Google",
    facebook: "Pokračovať na Facebooku",
    termsText:
      "Klepnutím na tlačidlo Pokračovať súhlasíš s našimi <2> podmienkami používania služieb</2> a <6> zásadami ochrany osobných údajov.</6>",
    or: "or",
    loginWith: "{{type}} sa pomocou e-mailu",
    installedMessage: "Elia je nainštalovaná v tvojom prehliadači! 🎉 ",
    toUse: "Pre používanie Elie sa musíš prihlásiť.",
    toUseRegister: `Ak chceš používať Eliu, nastav ju v 6 rýchlych krokoch.`,
    forgotPassword: "Zabudol(a) si heslo?",
    enterNewEmail: "Zadaj svoju e-mailovú adresu pre nastavenie nového hesla.",
    send: "Odoslať",
    dontHaveAccountYet: "Ešte nemáš účet?",
    registerHere: "Zaregistruj sa tu.",
    email: "Email",
    startForFree: `Začni zadarmo`,
    password: "Heslo",
    agreeLogin:
      "Klepnutím na tlačidlo Prihlásiť sa súhlasíš s našimi <2>podmienkami používania služieb</2> a <6> zásadami ochrany osobných údajov</6>.",

    startLearning: "Choď na akúkoľvek anglickú stránku",
    goToPopular:
      "Ak chceš začať používať Eliu, prejdi na akúkoľvek anglickú <br /> stránku alebo si vyber jednu z nižšie uvedených:",
    click: "Klikni",
    openElia: "pre otvorenie Elie  kedykoľvek",

    // for steps
    "Log in": "Prihlásiť sa",
    "Start Learning": "Začni s učením",

    haveAccount: "Už máš účet?",
    loginHere: "Prihlás sa tu",
    confirmPassword: "Potvrď heslo",
    agreeRegister:
      "Kliknutím na registrovať súhlasíš s našimi <2>Podmienkami služby</2> a <6>Zásadami ochrany osobných údajov</6>.",
    Registration: "Registrácia",
    "Native Language": "Materinský jazyk",
    "English Level": "Úroveň angličtiny",
    "Select Age": "Vyber vek",
    "Select Use": "Vyber použitie",

    validEmail: "*Zadaj platný e-mail.",
    emailRequired: "*Vyžaduje sa e-mail!",
    passwordRequired: "*Vyžaduje sa heslo!",
    confirmPasswordRequired: "*Vyžaduje sa potvrdenie hesla!",
    mustContain: "*Heslo musí obsahovať aspoň 5 znakov.",
    dontMatch: "*Heslá sa nezhodujú.",

    // REMOVED GOALS, USE AGE INSTEAD
    age: "Vek",
    howOld: "Koľko máš rokov?",
    "17 or less": "17 alebo menej",
    "18 - 24": "18 - 24",
    "25 - 34": "25 - 34",
    "35 - 44": "35 - 44",
    "45 or more": "45 a viac",

    engLvl: "Úroveň angličtiny",
    completedLvl: "Moja dokončená úroveň angličtiny je ...",
    elementary: "A2 Začiatočník",
    intermediate: "B1 Stredne pokročilý",
    upperIntermediate: "B2 Vyššie stredne pokročilý",
    advanced: "C1 Pokročilý",
    proficient: "C2 Profesionál",
    notSure: "Nevieš? Skús si tipnúť. Neskôr to môžeš zmeniť.",

    open: "Otvoriť Eliu",
    installed:
      "Elia je aplikácia nainštalovaná v tvojom prehliadači. <br /> Eliu môžeš otvoriť takto ↓",
    translations: "Materinský jazyk",
    wantTranslations: "Môj materinský (najznalejší) jazyk je:",
    mostPopular: "Najbežnejšie",
    cs: "Čeština",
    fr: "Francúzština",
    de: "Nemčina",
    fa: "Perzština",
    pt: "Portugalčina",
    pl: "Poľský",
    sk: "Slovenský",
    es: "Španielčina",
    others: "Iné",

    af: "Afrikánčina",
    ar: "Arabčina",
    bn: "Bangla",
    bs: "Bosnian",
    bg: "Bulharčina",
    ca: "Katalánčina",
    zh: "Čínština",
    hr: "Chorvátčina",
    da: "Dánčina",
    nl: "Holandčina",
    et: "Estónčina",
    fi: "Fínčina",
    el: "Gréčtina",
    ht: "Haitská kreolčina",
    he: "Hebrejčina",
    hi: "Hindčina",
    hm: "Hmong Daw",
    hu: "Maďarčina",
    is: "Islandčina",
    id: "Indonézčina",
    it: "Taliančina",
    ja: "Japončina",
    kl: "Klingončina",
    ko: "Kórejčina",
    lv: "Lotyština",
    lt: "Litovčina",
    ms: "Malajčina",
    mt: "Maltčina",
    no: "Nórčina",
    ro: "Rumunčina",
    ru: "Ruština",
    sr: "Srbčina",
    sw: "Svahilčina",
    sv: "Švédčina",
    ta: "Tamilčina",
    th: "Thajčina",
    tr: "Turečtina",
    uk: "Ukrajinčina",
    ur: "Urdučina",
    vi: "Vietnamčina",
    cy: "Waleština",

    use: "Použitie",
    planToUse: "Plánujem používať Eliu:",

    highSchool: "štúdium strednej školy",
    university: "štúdium na univerzite",
    workFreelance: "prácu ako freelancer",
    workCorporate: "prácu v korporátnom prostredí",
    workSmallBusiness: "prácu v malom podniku / startupe",
    freeTime: "vo svojom voľnom čase",

    next: "ĎALEJ",

    enjoy: "Choď a vychutnajte si",
    freeVersion: "bezplatnú verziu Elie!",
    thanks: "Poďakovanie od tímu Elia!",
    successSub: "Úspešne ste sa prihlásili na odber.",
    takeMe: "Vezmi ma na najekú anglickú webovú stránku",
    nowGo: "Teraz si choď zlepšiť úroveň svojej angličtiny.",
    choose: "Vyber si svoj plán",
    currentPlan: "TVOJ AKTUÁLNY PLÁN",
    perMonth: "za mesiac",
    billedYearly: "Účtuje sa ročne",
    billedMontly: "Účtované mesačne",
    saved: "ušetri 60%",
    twoCoffees: "za 2 kávy mesačne",
    packOfCoffee: "za balenie dobrej kávy",
    coffeeMonth: "za 1 kávu mesačne",
    billedYearlyAs: "účtované ročne ako {{price}}€",
    choosePlan: "Vyber si svoj plán",
    allPlans: "Všetky plány majú",

    "7 Days Free Trial": "7-dňová bezplatná skúšobná verzia",
    "Unlimited Word Lookups": "Neobmedzené vyhľadávanie slov",
    "Unlimited Word Recommendations": "Neobmedzené odporúčanie slov",
    "Unlimited Word Practice": "Neobmedzené precvičovanie slovíčok",
    "Translations into 50+ languages": "Translations into 50+ languages",
    "Unlimited Words in Wordlist": "Neobmedzený počet slov v zozname slov",
    "PDF Integration": "Integrácia PDF",
    "Daily Goal Progress": "Denný pokrok",
    "100% Data Security": "100% Data Security",
    mostPopularCap: "NAJOBĽÚBENEJŠIE",
    freeTrial: "Začani 7-dňovú bezplatnú skúšobnú verziu",
    keepLearning: "Chcem sa ďalej učiť.",
    unlimitedLoookups: "Neobmedzené vyhľadávanie slov",
    wordRecommend: "odporúčaní slov",
    wordPractice: "slovíčok na precvičenie",
    wordsWordlist: "slov v zozname slov",
    dailyGoal: "Denný pokrok",
    pdfIntegration: "Integrácia PDF",
    unlimited: "Neobmedzené",
    fasterLearning9x: "Chcem 9x rýchlejšie učenie",
    fasterLearning3x: "Chcem 3x rýchlejšie učenie",

    resent: "Poslali sme ti kód na obnovenie hesla na tvoj e-mail.",
    enterCode: "Zadaj kód tu.",
    code: '"Kód"',
    newPassword: "Zadaj svoje nové heslo.",
    confirm: "Potvrdiť",
    didntReceive: "Neprišiel ti e-mail na obnovenie hesla?",
    didntReceiveText: `
    👉 Skontroluj svoj spamový priečinok.
    <br />
    👉 Skontroluj, či si zadal(a) správnu e-mailovú adresu.
    <br />
    👉 Skús to znova o hodinu.
    <br />
    👉 Kontaktuj nás: support@getelia.com.
    `,
    resend: "Znova odoslať",
    linkExpired: "Odkaz vypršal",
    cantReset: `Nemôžeš obnoviť heslo? Možno ti to trvalo viac ako hodinu kliknúť na odkaz v e-maile, ktorý sme ti poslali.`,
    clickRequest: "👉 Klikni na Požiadať o nový e-mail",
    requestNewEmail: "Požiadať o nový e-mail",
    codeRequired: "*Vyžaduje sa kód!",
    // Pricing V4
    Monthly: `Mesačný`,
    Yearly: `Ročný`,
    Lifetime: `Doživotný`,
    save: `ušetri`,
    "per month": "mesačne",
    "per year": "ročne",
    "one time": "jednorázovo",
    cancel: `Možnosť kedykoľvek zrušiť.`,
    "Users usually master": "Používatelia zvyčajne ovládajú viac ako",
    "words & phrases": "slov a fráz ",
    "in this plan.": "v tomto pláne.",
    "The sky is the limit": "The sky is the limit",
    "This is an advanced phrase for": "Toto je rozšírená fráza pre ",
    unlimited: "neobmedzený",
    "You with Elia": "Ty s Eliou",
  },
};
