import { useState } from "react";

export default function WrapperLogic() {
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  function setWrapperData(appheader, appsubHeader) {
    console.log("Wrapper", appheader, appsubHeader);
    if (header !== appheader) setHeader(appheader);
    if (subHeader !== appsubHeader) setSubHeader(appsubHeader);
  }
  return {
    header,
    subHeader,
    setWrapperData,
  };
}
