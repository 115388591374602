import React, { lazy, Suspense, useEffect } from "react";
import { withWrap } from "./components/Wrapper";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Outlet,
} from "react-router-dom";
import useAnalytics from "./common/Segment";
import TagManager from "react-gtm-module";
import { LOCAL_STORAGE_LNG_KEY } from "./common/data";
import { useTranslation } from "react-i18next";

import("./styles.css");
import("./media.css");
const Login = lazy(() => import("./Pages/Login"));
const Register = lazy(() => import("./Pages/Register"));
const PasswordReset = lazy(() => import("./Pages/PasswordReset"));
const ThankYou = lazy(() => import("./Pages/ThankYou"));
const Subscription = lazy(() => import("./Pages/Subscription"));
const SocialCallback = lazy(() => import("./Pages/SocialCallback"));
const SocialCallAuthenticated = lazy(() =>
  import("./Pages/SocialCallback/Authenticated")
);
export default function App() {
  const [, Btrack, I] = useAnalytics();
  async function onUnload() {
    Btrack("Exit Page", window["ELIA_CURRENT_OPEN_PAGE"]);
  }
  useEffect(() => {
    window["APP_IDENTIFY"] = I;
    TagManager.initialize({
      gtmId: `GTM-MFGK6JX`,
    });
    window.addEventListener("beforeunload", onUnload);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_LNG_KEY)) {
      localStorage.setItem(
        LOCAL_STORAGE_LNG_KEY,
        window.location.pathname.slice(1, 3)
      );
    }
  }, []);
  return (
    <>
      <Suspense fallback={<AppLoading />}>
        <Routes>
          <Route>
            <Route path="login/*" element={<Login />} />
            <Route path="register/*" element={<Register />} />
            <Route path="password-reset/*" element={<PasswordReset />} />
            <Route path="thank-you" element={<ThankYou />} />
            <Route path="pricing" element={<Subscription />} />
            <Route path="/:lng/login/*" element={<Login />} />
            <Route path="/:lng/register/*" element={<Register />} />
            <Route path="/:lng/password-reset/*" element={<PasswordReset />} />
            <Route path="/:lng/thank-you" element={<ThankYou />} />
            <Route path="/:lng/pricing" element={<Subscription />} />
            <Route
              path="socialcall/authenticated"
              element={<SocialCallAuthenticated />}
            />
            <Route path="socialcall" element={<SocialCallback />} />
            {/* <Route path="/*" element={<AppLoading />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

function LanguageWrapper(props) {
  const { i18n } = useTranslation();
  const A = useParams();
  useEffect(() => {
    // Set language context for i18next
    if (A.lng) {
      i18n.changeLanguage(A.lng);
    }
  }, [A.lng, i18n]);

  return <Outlet />;
}

const { pathname } = window.location;
const pathCondition =
  pathname.startsWith("/thank-you") || pathname.startsWith("/pricing");
function Loading() {
  if (pathCondition) return null;
  return (
    <div className="loading">
      <img src={`${process.env.PUBLIC_URL}/assets/spinner.gif`} alt="spinner" />
    </div>
  );
}
const AppLoading = pathCondition ? Loading : withWrap(Loading);
