export const LoginSteps = ["Log in", "Start Learning"];
export const LOCAL_STORAGE_LNG_KEY = "elia_language";
export const RegisterSteps = [
  "Registration",
  "Native Language",
  "English Level",
  "Select Age",
  "Select Use",
  "Start Learning",
];
export const translationsData = {
  cs: "Czech",
  fr: "French",
  de: "German",
  fa: "Persian",
  pt: "Portuguese",
  pl: "Polish",
  sk: "Slovak",
  es: "Spanish",
};
export const levelsData = [
  // {
  //   key: "beginner",
  //   value: "A1 Beginner",
  // },
  {
    key: "elementary",
    value: "A2 Starter",
  },
  {
    key: "intermediate",
    value: "B1 Intermediate",
  },
  {
    key: "upperIntermediate",
    value: "B2 Upper-intermediate",
  },
  {
    key: "advanced",
    value: "C1 Advanced",
  },
  {
    key: "proficient",
    value: "C2 Professional",
  },
];
export const usage = [
  {
    text: "high school studies",
    event: "highSchool",
  },
  {
    text: "university studies",
    event: "university",
  },
  {
    text: "work as a freelancer",
    event: "workFreelance",
  },
  {
    text: "work in a corporate setting",
    event: "workCorporate",
  },
  {
    text: "work in a small business / startup",
    event: "workSmallBusiness",
  },
  {
    text: "in my free time",
    event: "freeTime",
  },
];
export const ageItems = [
  {
    text: "17 or less",
    eventTag: "17orLess",
  },
  {
    text: "18 - 24",
    eventTag: "18-24",
  },
  {
    text: "25 - 34",
    eventTag: "25-34",
  },
  {
    text: "35 - 44",
    eventTag: "35-44",
  },
  {
    text: "45 or more",
    eventTag: "45orMore",
  },
];
export const amplitudeDevKey = `f120388c536645ef104d8d5c6ed173b2`;
export const amplitudeLiveKey = `ffa32bfdd0a51db2f63889fd6c2ed781`;
export const Termslink = `https://docs.google.com/document/d/e/2PACX-1vRzRJfjVKxDk2UsVJz88tDH6CQ7s84rGxmc9Es_waSwHoVmXYIKYo9iNk1l3bOekpFNWnysMR7-j3oA/pub`;
export const Privacylink = `https://docs.google.com/document/d/e/2PACX-1vRpTamWZn_xToD7kRL1KCq05p3jUWNMbS7vNv_1TGG3ybwHOpIDt-sV2jRjyM-iYEraZxMx_CA6LxS0/pub`;
export const OpenLinksSources = {
  A1: {
    bbc: "https://www.bbc.com/news/world-middle-east-63851215#:~:text=real%20name)%20late-,at,-night%2C%20after%20her",
    wikipedia: "https://en.wikipedia.org/wiki/Cigarettes_and_Valentines",
    ted: "https://ideas.ted.com/is-it-normal-forgetting-or-alzheimers-dementia/#:~:text=attention%20to%20them-,.,-But%20losing%20your",
  },
  A2: {
    bbc: "https://www.bbc.com/news/science-environment-63957085#:~:text=can%20be%20dangerous-,and,-must%20be%20stored",
    wikipedia:
      "https://en.wikipedia.org/wiki/Visible_minority#:~:text=Ormeaux%20(38.8%25-,),-Montr%C3%A9al%20(34.2",
    ted: "https://ideas.ted.com/is-it-normal-forgetting-or-alzheimers-dementia/#:~:text=I%20got%20here.%E2%80%9D-,Or,-you%E2%80%99re",
  },
  B1: {
    bbc: "https://www.bbc.com/sport/sports-personality/64027088#:~:text=If%20it%20was%2C-,it,-truly%20was%20the",
    wikipedia: "https://en.wikipedia.org/wiki/Portage_Lake_District_Library",
    ted: "https://ideas.ted.com/why-boys-should-read-girl-books/#:~:text=read%20about%20boys-,.,-So%20what%20does",
  },
  B2: {
    bbc: "https://www.bbc.com/worklife/article/20230522-ai-optimism-how-embracing-artificial-intelligence-is-getting-workers-ahead#:~:text=jobs%2C%20far%20more-,%E2%80%93,-70%25%20%E2%80%93%20would",
    wikipedia: "https://en.wikipedia.org/wiki/Cigarettes_and_Valentines",
    ted: "https://ideas.ted.com/how-to-add-new-life-to-your-relationships-even-your-best-ones/#:~:text=we%20have%20to-,be,-more%20deliberate.%20We",
  },
  C1: {
    bbc: "https://www.bbc.com/news/science-environment-64030656#:~:text=stopped%20short%20of-,a,-Paris%20moment.",
    wikipedia:
      "https://en.wikipedia.org/wiki/Language_learning_strategies#:~:text=self%20regulation%22-,.,-Classification%20of%20language",
    ted: "https://ideas.ted.com/how-to-add-new-life-to-your-relationships-even-your-best-ones/#:~:text=not%20be%20receiving-,a,-certain%20kind%20of",
  },
  C2: {
    bbc: "https://www.bbc.com/news/world-europe-65685661#:~:text=abuse%20was%20part-,of,-the%20problem.",
    wikipedia: "https://en.wikipedia.org/wiki/Gingivoplasty",
    ted: "https://ideas.ted.com/feeling-anxious-the-way-you-breathe-could-be-adding-to-it/#:~:text=What%20they%E2%80%99re%20discovering-,is,-that%20breathing%20could",
  },
};

export const extraTranslations = {
  af: "Afrikaans",
  ar: "Arabic",
  bn: "Bangla",
  bs: "Bosnian",
  bg: "Bulgarian",
  ca: "Catalan",
  zh: "Chinese",
  hr: "Croatian",
  da: "Danish",
  nl: "Dutch",
  et: "Estonian",
  fi: "Finnish",
  el: "Greek",
  ht: "Haitian Creole",
  he: "Hebrew",
  hi: "Hindi",
  hm: "Hmong Daw",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  it: "Italian",
  ja: "Japanese",
  kl: "Klingon",
  ko: "Korean",
  lv: "Latvian",
  lt: "Lithuanian",
  ms: "Malay",
  mt: "Maltese",
  no: "Norwegian",
  pl: "Polish",
  ro: "Romanian",
  ru: "Russian",
  sr: "Serbian",
  sl: "Slovenian",
  sw: "Swahili",
  sv: "Swedish",
  ta: "Tamil",
  th: "Thai",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  vi: "Vietnamese",
  cy: "Welsh",
};
