export const pl = {
  translation: {
    loginTitle: "Zaloguj się do Elia",
    login: "Zaloguj się",
    register: "Rejestr",
    here: "tutaj",
    slogan: `“Nauczyłam się więcej słów z Elią w ciągu miesiąca niż kiedykolwiek!”`,
    author: "Kirsten Storz, studentka, 24 lata",
    google: "Kontynuuj z Google",
    facebook: "Kontynuuj za pomocą Facebooka",
    termsText:
      "Klikając Kontynuuj, zgadzasz się na nasze <2> Warunki korzystania z usługi</2> i <6>Politykę prywatności</6>.",
    or: "or",
    loginWith: "{{type}} się za pomocą adresu e-mail",
    installedMessage: "Elia jest zainstalowana w Twojej przeglądarce! 🎉 ",
    toUse: "Musisz się zalogować, aby korzystać z Elia.",
    toUseRegister: `Aby korzystać z Elia, skonfiguruj ją w 6 szybkich krokach.`,
    forgotPassword: "Zapomniałeś hasła?",
    enterNewEmail: "Wprowadź swój adres e-mail, aby ustawić nowe hasło.",
    send: "Wyślij do",
    dontHaveAccountYet: "Nie masz jeszcze konta?",
    registerHere: "Zarejestruj się tutaj.",
    email: "Email",
    startForFree: `Zacznij za darmo`,
    password: "Hasło",
    agreeLogin:
      "Klikając przycisk Zaloguj się, wyrażasz zgodę na nasze <2>Warunki korzystania z usługi</2> i <6>Politykę prywatności</6>.",

    startLearning: "Przejdź do dowolnej angielskiej strony",
    goToPopular:
      "Jeśli chcesz zacząć korzystać z Elia, przełącz się na dowolną <br /> angielską stronę lub wybrać jedną z wymienionych poniżej:",
    click: "Kliknij",
    openElia: "aby otworzyć Elie anytime",

    // for steps
    "Log in": "Zaloguj się",
    "Start Learning": "Rozpocznij naukę",

    haveAccount: "Masz już konto?",
    loginHere: "Zaloguj się tutaj",
    confirmPassword: "Potwierdź hasło",
    agreeRegister:
      "Klikając zarejestruj się, wyrażasz zgodę na nasze <2>Warunki świadczenia usług</2> i <6>Politykę prywatności</6>.",
    Registration: "Rejestr",
    "Native Language": "Język ojczysty",
    "English Level": "Poziom angielskiego",
    "Select Age": "Wybierz wiek",
    "Select Use": "Wybierz użycie",

    validEmail: "*Wprowadź prawidłowy adres e-mail.",
    emailRequired: "*Wymagany adres e-mail!",
    passwordRequired: "*Wymagane hasło!",
    confirmPasswordRequired: "*Wymagane potwierdzenie hasłem!",
    mustContain: "*Hasło musi zawierać co najmniej 5 znaków.",
    dontMatch: "*Hasła nie pasują.",

    age: "Wiek",
    howOld: "Ile masz lat?",
    "17 or less": "17 lub mniej",
    "18 - 24": "18 - 24",
    "25 - 34": "25 - 34",
    "35 - 44": "35 - 44",
    "45 or more": "45 lub więcej",

    engLvl: "Poziom angielskiego",
    completedLvl: "Mój ukończony poziom angielskiego to...",
    elementary: "A2 Początkujący",
    intermediate: "B1 Średniozaawansowany",
    upperIntermediate: "B2 Umiarkowanie zaawansowany",
    advanced: "C1 Zaawansowany",
    proficient: "C2 Profesjonalny",
    notSure:
      "Nie jestem pewny/a? Po prostu zgadnij. Możesz to później zmienić.",

    open: "Otwórz Elia",
    installed:
      "Elia to aplikacja zainstalowana w przeglądarce. <br /> Możesz otworzyć Elia w ten sposób ↓",
    translations: "Język ojczysty",
    wantTranslations: "Moim ojczystym (najbardziej biegłym) językiem jest:",
    mostPopular: "Najczęściej",
    cs: "Czeski",
    fr: "Francuski",
    de: "Niemiecki",
    fa: "Perski",
    pt: "Portugalski",
    pl: "Polski",
    sk: "Słowacki",
    es: "Hiszpański",
    others: "Inne",

    af: "Afrikaans",
    ar: "Arabski",
    bn: "Bengalski",
    bs: "Bośniacki",
    bg: "Bułgarski",
    ca: "Kataloński",
    zh: "Chiński",
    hr: "Chorwacki",
    da: "Duński",
    nl: "Holenderski",
    et: "Estoński",
    fi: "Fiński",
    el: "Grecki",
    ht: "Haitański kreolski",
    he: "Hebrajski",
    hi: "Hinduski",
    hm: "Hmong Daw",
    hu: "Węgierski",
    is: "Islandzki",
    id: "Indonezyjski",
    it: "Włoski",
    ja: "Japoński",
    kl: "Klingoński",
    ko: "Koreański",
    lv: "Łotewski",
    lt: "Litewski",
    ms: "Malajski",
    mt: "Maltański",
    no: "Norweski",
    ro: "Rumuński",
    ru: "Rosyjski",
    sr: "Serbski",
    sw: "Suahili",
    sv: "Szwedzki",
    ta: "Tamilski",
    th: "Tajski",
    tr: "Turecki",
    uk: "Ukraiński",
    ur: "Urdu",
    vi: "Wietnamski",
    cy: "Walijski",

    use: "Użycie",
    planToUse: "Planuję używać Elia:",

    highSchool: "nauka w szkole średniej",
    university: "studia na uniwersytecie",
    workFreelance: "praca jako freelancer",
    workCorporate: "praca w środowisku korporacyjnym",
    workSmallBusiness: "praca w małej firmie/startupie",
    freeTime: "w wolnym czasie",

    next: "WIĘCEJ",

    enjoy: "Idź i ciesz się",
    freeVersion: "darmową wersją Elia!",
    thanks: "Podziękowania od zespołu Elia!",
    successSub: "Udało Ci się zapisać.",
    takeMe: "Zabierz mnie na angielską stronkę",
    nowGo: "Teraz podnieś poziom swojego angielskiego.",
    choose: "Wybierz swój plan",
    currentPlan: "TWÓJ OBECNY PLAN",
    perMonth: "miesięcznie",
    billedYearly: "Rozliczany rocznie",
    billedMontly: "Rozliczany miesięcznie",
    saved: "oszczędzaj 60%",
    twoCoffees: "za 2 kawy miesięcznie",
    packOfCoffee: "za paczkę dobrej kawy",
    coffeeMonth: "za 1 kawę miesięcznie",
    billedYearlyAs: "rozliczenie roczne {{price}}€",
    choosePlan: "Wybierz swój plan",
    allPlans: "Wszystkie plany mają",

    "7 Days Free Trial": "7-dniowy bezpłatny okres próbny",
    "Unlimited Word Lookups": "Nieograniczone wyszukiwanie słów",
    "Unlimited Word Recommendations": "Nieograniczone polecanie słów",
    "Unlimited Word Practice": "Nieograniczona praktyka słów",
    "Translations into 50+ languages": "Translations into 50+ languages",
    "Unlimited Words in Wordlist": "Nieograniczona liczba słów na liście słów",
    "PDF Integration": "Integracja z PDF",
    "Daily Goal Progress": "Codzienne postępy w osiąganiu celów",
    "100% Data Security": "100% Data Security",
    mostPopularCap: "NAJBARDZIEJ POPULARNE",
    freeTrial: "Rozpocznij 7-dniowy bezpłatny okres próbny",
    keepLearning: "Chcę się dalej uczyć.",
    unlimitedLoookups: "Nieograniczone wyszukiwanie słów",
    wordRecommend: "rekomendacji słów",
    wordPractice: "Ćwiczenie słów",
    wordsWordlist: "słów na liście słów",
    dailyGoal: "Codzienne postępy w osiąganiu celów",
    pdfIntegration: "Integracja z PDF",
    unlimited: "Nieograniczone",
    fasterLearning9x: "Chcę uczyć się 9 razy szybciej",
    fasterLearning3x: "Chcę uczyć się 3x szybciej",

    resent: "Na Twój adres e-mail wysłaliśmy kod resetowania hasła.",
    enterCode: "Wprowadź kod tutaj.",
    code: '"Kod"',
    newPassword: "Wprowadź nowe hasło.",
    confirm: "Potwierdź",
    didntReceive: "Nie otrzymałeś wiadomości e-mail z resetem hasła?",
    didntReceiveText: `
    👉 Sprawdź folder spamu.
    <br />
    👉 Sprawdź, czy podałeś prawidłowy adres e-mail.
    <br />
    👉 Spróbuj ponownie za godzinę.
    <br />
    👉 Skontaktuj się z nami: support@getelia.com.
    `,
    resend: "Wyślij ponownie",
    linkExpired: "Link wygasł",
    cantReset: `Nie możesz zresetować hasła? Być może kliknięcie linku w wysłanej przez nas wiadomości e-mail zajęło więcej niż godzinę.`,
    clickRequest: "👉 Kliknij przycisk Poproś o nową wiadomość e-mail.",
    requestNewEmail: "Poproś o nowy adres e-mail",
    codeRequired: "*Wymagany kod!",
    Monthly: `Miesięczny`,
    Yearly: `Roczny`,
    Lifetime: `Dożywotni`,
    save: `zaoszczędź`,
    "per month": "miesięcznie",
    "per year": "rocznie",
    "one time": "jednorazowo",
    cancel: `Możliwość anulowania w dowolnym momencie.`,
    "Users usually master":
      "W tym planie użytkownicy zazwyczaj opanowują ponad",
    "words & phrases": "słów i fraz.",
    "in this plan.": "",
    "The sky is the limit": "The sky is the limit",
    "This is an advanced phrase for":
      "To zaawansowane określenie dla możliwości",
    unlimited: "bez granic",
    "You with Elia": "Ty z Elia",
  },
};
