import React, { Children, isValidElement, cloneElement } from "react";
import useLogic from "./WrapperLogic";
import { useLocation } from "react-router-dom";
import { LanguageSwitcher } from "../LanguageSwitcher";

export default function Wrapper({ children }) {
  const location = useLocation();

  const { header, subHeader, setWrapperData } = useLogic();
  const MappedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { setWrapperData });
    }
    return child;
  });
  return (
    <>
      <LanguageSwitcher />
      <div className="pageHeader column">
        {header && <h1>{header}</h1>}
        {subHeader && <span>{subHeader}</span>}
      </div>
      <div className="appbox">{MappedChildren}</div>
    </>
  );
}
export function withWrap(Component) {
  return function () {
    return (
      <Wrapper>
        <Component />
      </Wrapper>
    );
  };
}
